import { useEffect, useState } from "react";
import './App.css';

function App() {
  const { pathname } = new URL(window.location.href);

  const centraApiUrl = "https://frend.centraqa.com/api/checkout/";
  const centraApiSecret = "gcuRu5uJtn5WlJm5lR3FF-Molw2bO45a";
  const frendVippsApiUrl = "https://vipps.frendfactory.workers.dev/";

  const [token, setToken] = useState(null);
  const [selection, setSelection] = useState(null);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState(null);
  const [selectionId, setSelectionId] = useState(null);
  const [signature, setSignature] = useState(null);
  const [products, setProducts] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState(null);

  const [phone, setPhone] = useState(46769310);
  const [email, setEmail] = useState("firstname@example.com");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");

  const getProducts = async () => {
    const response = await fetch(`${centraApiUrl}products`, {
      method: "POST",
      headers: {
        "API-Authorization": centraApiSecret
      },
      body: JSON.stringify({
        "pricelist": 23,
        "market": 1,
        "onlyAvailable": true
      })
    });

    const json = await response.json();

    return json.products;
  }

  const getSelection = async () => {
    const response = await fetch(`${centraApiUrl}selection`, {
      method: "GET",
      headers: {
        "API-Authorization": centraApiSecret
      }
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = await response.json();
    setToken(json.token);
    setSelection(json.selection);
    setAvailablePaymentMethods(json.paymentMethods);
  }

  const addToCart = async (target, itemId) => {
    const response = await fetch(`${centraApiUrl}items/${itemId}`, {
      method: "POST",
      headers: {
        "API-Authorization": centraApiSecret,
        "API-token": token
      },
      body: JSON.stringify({
        "quantity": 1
      })
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = await response.json();
    setSelection(json.selection);

    target.className = "selectied";
  }

  const setPaymentMethod = async (paymentMethod) => {
    setPaymentMethodId(paymentMethod);

    const response = await fetch(`${centraApiUrl}payment`, {
      method: "POST",
      headers: {
        "API-Authorization": centraApiSecret,
        "API-token": token
      },
      body: JSON.stringify({
        "paymentMethod": paymentMethod,
        "paymentReturnPage": "https://vipps-front.pages.dev:3000/success",
        "paymentFailedPage": "https://vipps-front.pages.dev:3000/failure",
        "address": {
          "email": email,
          "phoneNumber": phone,
          "firstName": firstname,
          "lastName": lastname,
          "address1": address,
          "zipCode": zip,
          "city": city,
        }
      })
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const method = await response.json();

    if (method.action === "external") {
      const signaturePayload = window.btoa(`frend:${method.selection}:${token}`);
      setSelectionId(method.selection);
      setSignature(signaturePayload);
    }
  }

  const initiatePayment = async () => {
    const response = await fetch(frendVippsApiUrl, {
      method: "POST",
      headers: {
        "X-Frend-Signature": signature
      },
      body: JSON.stringify({
        "phone": phone,
        "amount": (selection.totals.grandTotalPriceAsNumber * 100),
        "orderId": selectionId
      })
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const payment = await response.json();

    console.log(payment);

    if (payment.url) {
      window.open(payment.url, "_self");
    }
  }

  useEffect(() => {
    getSelection();
    async function fetchProducts() {
      const products = await getProducts();
      setProducts(products);
    }
    fetchProducts();
  }, []);

  return (
    <>
      {pathname === "/summary" && <div className="message">Your order was successfully placed!</div>}
      <div className="container">

        <div className="products">
          {products && products.map((product) => {
            return (
              <div className="product" key={product.product}>
                <p><strong>{product.name}</strong><span>{product.variantName}</span></p>
                <p>{product.price}</p>
                <div className="items">
                  {product.items.map((item) => {
                    if (item.stock <= 0) {
                      return <span key={item.item} className="unavailable">{item.name}</span>
                    }
                    return <span key={item.item} onClick={(e) => addToCart(e.target, product.items[0].item)}>{item.name}</span>
                  })}
                </div>
              </div>
            )
          })}
        </div>

        <div className="checkout">
          {selection &&
            <>
              {<p>Subtotal: <span>{selection.totals.itemsTotalPrice}</span></p>}
              {<p>Shipping: <span>{selection.totals.shippingPrice}</span></p>}
              {<p>Cart total: <span>{selection.totals.grandTotalPrice}</span></p>}

              <div className="payment">
                <div>
                  <p className="field">
                    <label htmlFor="firstname">Firstname</label>
                    <input type="text" value={firstname} name="firstname" id="firstname" onChange={(e) => { setFirstname(e.target.value) }} />
                  </p>
                  <p className="field">
                    <label htmlFor="lastname">Lastname</label>
                    <input type="text" value={lastname} name="lastname" id="lastname" onChange={(e) => { setLastname(e.target.value) }} />
                  </p>
                </div>
                <p className="field">
                  <label htmlFor="phone">Phone *</label>
                  <input type="tel" value={phone} name="phone" id="phone" onChange={(e) => { setPhone(e.target.value) }} />
                </p>
                <p className="field">
                  <label htmlFor="email">Email *</label>
                  <input type="email" value={email} name="email" id="email" onChange={(e) => { setEmail(e.target.value) }} />
                </p>
                <p className="field">
                  <label htmlFor="address">Address</label>
                  <input type="text" value={address} name="address" id="address" onChange={(e) => { setAddress(e.target.value) }} />
                </p>
                <div>
                  <p className="field">
                    <label htmlFor="zip">Zip code</label>
                    <input type="text" value={zip} name="zip" id="zip" onChange={(e) => { setZip(e.target.value) }} />
                  </p>
                  <p className="field">
                    <label htmlFor="city">City</label>
                    <input type="text" value={city} name="city" id="city" onChange={(e) => { setCity(e.target.value) }} />
                  </p>
                </div>
              </div>

              <div className="methods">
                {selection.items.length > 0 && availablePaymentMethods && availablePaymentMethods.map((method) => {
                  return (
                    <p key={method.paymentMethod} onClick={() => setPaymentMethod(method.paymentMethod)} className={paymentMethodId === method.paymentMethod ? "selected" : ""}>
                      {method.name}
                    </p>
                  )
                })}
              </div>

              {paymentMethodId && <button onClick={initiatePayment}>Pay now</button>}
            </>
          }
        </div>

      </div>
    </>
  );
}

export default App;
